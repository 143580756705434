import { AuthGuard } from '../../global';
import * as pPenggajianPajak from './';

export const routePenggajianPajak = [
//    { path: 'rekapitulasi-perhitungan-absensi', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.RekapitulasiPerhitunganAbsensiModule) },
    { path: 'data-kehadiran-personal', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DataKehadiranPersonalModule) },
    { path: 'import-data-absensi', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.ImportDataAbsensiModule) },
    { path: 'rekapitulasi-perhitungan-lembur', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.RekapitulasiPerhitunganLemburModule) },
    { path: 'daftar-monitoring-penggajian', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DaftarMonitoringPenggajianModule) },
    //{ path: 'daftar-perhitungan-asuransi-kesehatan', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DaftarPerhitunganAsuransiKesehatanModule) },
    //{ path: 'daftar-perhitungan-pajak', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DaftarPerhitunganPajakModule) },
    { path: 'daftar-pemotongan-pajak', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DaftarPemotonganPajakModule) },
    { path: 'payroll', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.PayrollModule) },
    { path: 'cetak-slip-gaji', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.CetakSlipGajiModule) },
    { path: 'daftar-perhitungan-lembur', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DaftarPerhitunganLemburModule) },
    { path: 'bukti-potong-pajak', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.BuktiPotongPajakModule) },
    { path: 'monitoring-history-absensi', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.MonitoringHistoryAbsensiModule) },
    { path: 'pengajuan-pemberian-kesejahteraan-pegawai', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.PengajuanPemberianKesejahteraanPegawaiModule) },
    { path: 'daftar-pengajuan-pemberian-kesejahteraan-pegawai', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DaftarPengajuanPemberianKesejahteraanPegawaiModule) },
    { path: 'daftar-kirim-pengajuan-pemberian-kesejahteraan-pegawai', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DaftarKirimPengajuanPemberianKesejahteraanPegawaiModule) },
    { path: 'daftar-terima-pengajuan-pemberian-kesejahteraan-pegawai', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DaftarTerimaPengajuanPemberianKesejahteraanPegawaiModule) },
    
    { path: 'daftar-pengajuan-pemberian-kesejahteraan-pegawai-v2', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DaftarPengajuanPemberianKesejahteraanPegawaiV2Module) },
    { path: 'daftar-kirim-pengajuan-pemberian-kesejahteraan-pegawai-v2', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DaftarKirimPengajuanPemberianKesejahteraanPegawaiV2Module) },
    { path: 'daftar-terima-pengajuan-pemberian-kesejahteraan-pegawai-v2', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.DaftarTerimaPengajuanPemberianKesejahteraanPegawaiV2Module) },

    { path: 'laporan-pegawai-lembur', loadChildren : () => import('./page-penggajian-pajak.module').then(m => m.LaporanPegawaiLemburModule) },
    { path: 'laporan-keterlambatan', loadChildren : () => import('./page-penggajian-pajak.module').then(m => m.LaporanKeterlambatanModule) },
    { path: 'upload-gaji-pegawai', loadChildren : () => import('./page-penggajian-pajak.module').then(m => m.UploadGajiPegawaiModule) },
    { path: 'upload-gaji-pegawai-manual', loadChildren : () => import('./page-penggajian-pajak.module').then(m => m.UploadGajiPegawaiManualRSJPModule) },
    { path: 'convert-import-absensi', loadChildren : () => import('./page-penggajian-pajak.module').then(m => m.ConvertImportAbsensiModule) },
    { path: 'hitung-payroll', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.HitungPayrollModule) },
    { path: 'perhitungan-thr', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.PerhitunganTHRModule) },
    { path: 'rekapitulasi-ketidakhadiran', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.RekapitulasiKetidakhadiranModule) },
    { path: 'rekapitulasi-perhitungan-absensi2', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.RekapitulasiDanPerhitunganAbsensi2Module) },
    { path: 'rekapitulasi-ketidakhadiran2', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.RekapitulasiKetidakhadiran2Module) },
    { path: 'rekapitulasi-perhitungan-lembur2', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.RekapitulasiPerhitunganLembur2Module) },
    { path: 'hitung-payroll2', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.HitungPayroll2Module) },
  //REMUNERASI
  { path: 'rekapitulasi-perhitungan-remunerasi', loadChildren: () => import('./page-penggajian-pajak.module').then(m => m.RekapitulasiDanPerhitunganRemunerasiModule) },

];
